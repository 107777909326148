
import CartFavoriteIcon from './CartFavoriteIcon.vue'
export default {
    components: { CartFavoriteIcon },
    props: {
        treatment: {
            type: Object,
            default: null,
            required: true
        },
    },
    computed: {
      preselectedEmployee() {
        return this.$store.getters['cart/getPreselectedEmployee']
      }
    },
    methods: {
        formatPrice(treatment) {
            return Math.min(...treatment.pricePerSite.map(x => x.price)).toFixed(2).replace('.', ',')
        },
        treatmentRedirect(treatment) {
            this.$router.push(
                `behandlinger/${treatment.urlSafeTreatmentName}/${treatment.urlSafe}`
            );
        },
        isFavorite(treatment) {
            return this.$store.getters['treatments/getFavoriteTreatments'].map(x => x.treatmentId).includes(treatment.treatmentId)
        },
        async addOrRemove(treatment) {
            if (this.treatmentIsInCart(treatment)) {
                await this.$store.dispatch('cart/deleteShoppingCartItem', {item: treatment, type: 'T'})
                if (this.$store.getters['cart/getCartCount'] === 0) {
                  this.$root.$emit("closeAddedToCartDialog")
                }
            } else {
                await this.$store.dispatch('cart/insertOrUpdateTreatment', treatment, 'T')
            }
        },
        treatmentIsInCart(treatment) {
            return this.$store.getters['cart/treatmentIsInCart'](treatment.treatmentId)
        }
    }
}
