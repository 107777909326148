
import FixitDialog from '../../../components/FixitDialog.vue'
import CartFavoriteIcon from './CartFavoriteIcon.vue'
import {salonPool} from '../../../helpers/salonPool'

export default {
    components: { CartFavoriteIcon, FixitDialog },
    props: {
        product: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        soldOutDialog: false,
    }),
    computed: {
        chainId() {
            return this.$store.getters["cmsConfigHomepage/getChainId"];
        },
    },
    methods: {
        formatPrice() {
            return (this.product.price * this.product.quantity).toFixed(2).replace('.', ',')
        },
        productRedirect() {
            window.open(
                this.$config.PORTAL_URL +
                `/nettbutikk/${this.chainId}/produkt/${this.product.urlSafeName}/${this.product.base64Id}`,
                "_blank"
            );
        },
        isFavorite() {
            let favorites = this.$store.getters['products/getFavoriteProducts']
            if (favorites && favorites.length) {
                return this.$store.getters['products/getFavoriteProducts'].map(x => x.productId).includes(this.product.id)
            } else return false
        },
        async addOrRemove(action) {
            let salonsAvailableForCheckout = salonPool(this)
            let selectedProductStock = this.product.stockAmount;
            let selectedProductStockInSalonsAvailableForCheckout = selectedProductStock
                ?.filter(
                (p) =>
                    salonsAvailableForCheckout.includes(p.siteId) &&
                    p.productStockAmount >= this.product.quantity + 1
                )
                .map((s) => s.siteId);
            if (action === 'add') {
                if (selectedProductStockInSalonsAvailableForCheckout?.length) {
                    if (this.productIsInStock(this.product, selectedProductStockInSalonsAvailableForCheckout[0])) {
                        await this.$store.dispatch('cart/insertOrUpdateProduct', {
                            siteId: this.$store.getters['cart/getSalonPoolDefaultSiteId'],
                            isCompleted: false,
                            salePrice: this.product.price,
                            quantity: this.product.quantity + 1,
                            productId: this.product.base64Id,
                        })
                    }
                } else {
                    this.soldOutDialog = true
                }
            } else if (action === 'remove') {
                if (this.product.quantity - 1 <= 0) {
                    await this.$store.dispatch('cart/deleteShoppingCartItem', {item: this.product, type: 'P'})
                } else {
                    await this.$store.dispatch('cart/insertOrUpdateProduct', {
                        siteId: this.$store.getters['cart/getSalonPoolDefaultSiteId'],
                        isCompleted: false,
                        salePrice: this.product.price,
                        quantity: this.product.quantity - 1,
                        productId: this.product.base64Id,
                    })
                }
            } else {
                await this.$store.dispatch('cart/deleteShoppingCartItem', {item: this.product, type: 'P'})
            }
            if (this.$store.getters['cart/getCartCount'] === 0) {
              this.$root.$emit("closeAddedToCartDialog")
            }
        },
        productIsInCart() {
            return this.$store.getters['cart/productIsInCart'](this.product.id)
        },
        productIsInStock(
            product,
            siteId
        ) {
            return (
                product.quantity + 1 <=
                (product.stockAmount.find((x) => x.siteId === siteId)
                ?.productStockAmount || 0)
            );
        },
        itemDiscount() {
            let salePrice = this.product.price;
            let totalPriceForQuantity = 0;
            let discountedQuantity = 0;
            let discountText = '';
            if (this.product.discounts.length) {
                this.product.discounts.forEach((discount) => {
                    totalPriceForQuantity += discount.discountPrice * discount.quantity;
                    discountedQuantity += discount.quantity;
                    discountText = discount.discountText;
                });
            }
            if (discountedQuantity <= this.product.quantity && discountedQuantity > 0) {
                totalPriceForQuantity += this.product.price * (this.product.quantity - discountedQuantity);
                salePrice = totalPriceForQuantity / (this.product.quantity + discountedQuantity);
            }
            return {
                salePrice: salePrice,
                price: this.product.price,
                totalSalePrice: totalPriceForQuantity || this.product.price * this.product.quantity,
                totalValue: this.product.price * this.product.quantity,
                discounted: discountedQuantity > 0,
                discountText: discountText,
            };
        }
    }
}
