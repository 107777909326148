import CmsService from "../../services/cmsConfigService";
import { SettingTypeEnum } from "@/static/enums/index.js";

export const state = () => ({
  cmsConfigHomepage: {
    homepageId: null,
    homepageType: null,
    chainFixitSiteId: "test",
    homepageDescription: "",
    homepageTitle: "",
    chainId: null,
    cmsEnabled: true,
    cmsFixitNoEnabled: true,
    siteUrl: null,
    cmsReturnUrl: "",
    giftcardActive: false,
    webshopActive: false,
    template: {
      templateId: null,
      templateName: "",
      templateHomepageSettings: null,
      templateeSiteSettings: null,
    },
    sites: [],
    homepageBanners: [],
    appointmentButtonOverrideText: "",
    appointmentButtonOverrideUrl: "",
    showDepartments: false,
    modules: [],
    activeCustomerPrograms: [],
    homepageSocials: [],
  },
  chainNameAndLogo: {
    chainName: "",
    logo: "",
  },
  draftMode: false,
  cmsFound: false,
});
const getters = {
  getHomepageId: (state) => state.cmsConfigHomepage.homepageId,
  getChainId: (state) => state.cmsConfigHomepage.chainId,
  getCmsConfigHomepage: (state) => state.cmsConfigHomepage,
  getHomepageSocials: (state) => Object.values(state.cmsConfigHomepage.homepageSocials).filter(x => x.active),
  getHomepageDescription: (state) =>
    state.cmsConfigHomepage.homepageDescription,
  getTemplateSetting: (state) =>
    state.cmsConfigHomepage.template.templateHomepageSettings,
  getSites: (state) => state.cmsConfigHomepage.sites,
  getSiteIds: (state) =>
    state.cmsConfigHomepage.sites.map((site) => site.siteId),
  getBanners: (state) => state.cmsConfigHomepage.homepageBanners,
  getModules: (state) => state.cmsConfigHomepage.modules,
  getShowDepartments: (state) => state.cmsConfigHomepage.showDepartments,
  getGiftcardActiveStatus: (state) => state.cmsConfigHomepage.giftcardActive,
  getWebshopActiveStatus: (state) => state.cmsConfigHomepage.webshopActive,
  getActiveCustomerPrograms: (state) => state.cmsConfigHomepage.activeCustomerPrograms,
  getSiteModules: (state) => id => state.cmsConfigHomepage.sites.find(x => x.siteId == id).modules,
  getSiteUrl: (state) => state.cmsConfigHomepage.siteUrl || '',
  useCustomLogo: (state) => state.cmsConfigHomepage.useCustomLogo,
  getChainName: (state) => state.cmsConfigHomepage.homepageTitle ? state.cmsConfigHomepage.homepageTitle : state.cmsConfigHomepage.chainName,
  getLogo: (state) => state.cmsConfigHomepage.logo,
  getLogoLarge: (state) => state.cmsConfigHomepage.logoLarge,
  getPrimary: (state) => state.cmsConfigHomepage?.template?.templateHomepageSettings
                              ?.find((t) => t.settingType == SettingTypeEnum.COLOR1)?.settingValue,
  getSecondary: (state) => state.cmsConfigHomepage?.template?.templateHomepageSettings
                                ?.find((t) => t.settingType == SettingTypeEnum.COLOR2)?.settingValue,
  newsVisible: (state) => state.cmsConfigHomepage?.modules?.find( x => x.componentId === 'news')?.visible,
  productsVisible: (state) => state.cmsConfigHomepage?.modules?.find( x => x.componentId === 'suppliers')?.visible,
  instagramVisible: (state) => state.cmsConfigHomepage?.modules?.find( x => x.componentId === 'instagram-images')?.visible,
  getHomepageSiteUrl: (state) => state.cmsConfigHomepage?.siteUrl,
  getAppointmentButtonOverrideText: (state) => state.cmsConfigHomepage.appointmentButtonOverrideText,
  getAppointmentButtonOverrideUrl: (state) => state.cmsConfigHomepage.appointmentButtonOverrideUrl,
  isDraftMode: (state) => state.draftMode,
  getCmsFound: (state) => state.cmsFound,
  primaryAndSecondaryAreEqual: (state) => state.cmsConfigHomepage?.template?.templateHomepageSettings?.find((t) => t.settingType == SettingTypeEnum.COLOR1)?.settingValue == state.cmsConfigHomepage?.template?.templateHomepageSettings?.find((t) => t.settingType == SettingTypeEnum.COLOR2)?.settingValue
};

const mutations = {
  setCmsConfigHomepage(state, value) {
    state.cmsConfigHomepage = value;
    state.cmsConfigHomepage.homepageBanners.sort((a, b) => a.SortNo - b.SortNo);
  },
  SET_DRAFT_MODE(state, value) {
    state.draftMode = value
  },
  setActiveCustomerPrograms(state, value) {
    state.cmsConfigHomepage.activeCustomerPrograms = value
  },
  setCmsFound(state, value) {
    state.cmsFound = value;
  }
};
const actions = {
  async fetchCmsConfigByHomepageId({ commit, getters }, id) {
    const cmsService = new CmsService({
      axios: this.$axios,
      env: this.$config,
    });
    let result = await cmsService.GetCmsConfigByHomepageId(id, true);
    if (result && result.homepageId != null) {
      //REMOVE LATER - Tango requirement, remove when they want their webshop active again
      if (result.chainId == 1466) result.webshopActive = false
      commit('SET_DRAFT_MODE', true)
      commit("setCmsConfigHomepage", result);
      commit('setCmsFound', true);
      return true;
    }
    return false;
  },
  async fetchCmsConfigHomepage({ commit, getters }, host) {
    const cmsService = new CmsService({
      axios: this.$axios,
      env: this.$config,
    });
    let result = await cmsService.GetCmsConfigByHostName(host, false);
    if (result) {
      //REMOVE LATER - Tango requirement, remove when they want their webshop active again
      if (result.chainId == 1466) result.webshopActive = false
      commit('SET_DRAFT_MODE', false)
      commit("setCmsConfigHomepage", result);
      commit('setCmsFound', true);
      return true;
    }
    return false;
  },

  async fetchActiveCustomerPrograms({ commit, rootGetters }) {
    const cmsService = new CmsService({
      axios: this.$axios,
      env: this.$config,
    });
    const chainId = rootGetters["cmsConfigHomepage/getChainId"];
    if (chainId !== null) {
      let result = await cmsService.GetActiveCustomerPrograms(chainId);
      if (result) {
        commit("setActiveCustomerPrograms", result);
        return true;
      }
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
