import {SettingTypeEnum} from "@/static/enums/index.js";

var getReadableForeColorHex = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var hex = result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
  return (hex?.r + hex?.b + hex?.g) / 3 < 128 ? "#EEEEEE" : "#222222";
}

export default function (ctx) {
  var settings = ctx.store.getters["cmsConfigHomepage/getTemplateSetting"]
  var primary = settings?.find(
    (t) => t.settingType == SettingTypeEnum.COLOR1
  )?.settingValue;
  var secondary = settings?.find(
    (t) => t.settingType == SettingTypeEnum.COLOR2
  )?.settingValue;

  var primaryText = getReadableForeColorHex(primary)
  var secondaryText = getReadableForeColorHex(secondary)

  return {
    theme: {
      light: true,
      themes: {
        light: {
          base: '#393832',
          primary: primary,
          secondary: secondary,
          'primary-text': primaryText,
          'secondary-text': secondaryText,
          'cta-primary':primary,
				  'cta-secondary':secondary,
        },
        dark: {
          base: '#393832',
          primary: primary,
          secondary: secondary,
          'primary-text': primaryText,
          'secondary-text': secondaryText,
          'cta-primary':primary,
				  'cta-secondary':secondary,
        }
      },
      options: {
        customProperties: true
      }
    }
  }
}
