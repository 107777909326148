
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        titleIcon: {
            type: String,
        },
        show: {
            type: Boolean,
            required: true
        },
        maxWidth: {
            type: String,
            default: '770px'
        },
        fullscreenOnMobile: {
            type: Boolean,
            default: false
        },
        fitToSide: {
            type: Boolean,
            default: false
        },
        persistent: {
          type: Boolean,
          default: false
        }
    },
    computed: {
        dialog: {
            get: function() {
                return this.show
            },
            set: function() {
                this.$emit('toggle')
            }
        }
    }
}
