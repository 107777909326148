
import FIcon from "@fixit/icons/src/components/FIcon.vue";
import chooseSalonDialog from "@fixit/fixit-no-header/src/components/Common/CommonComponents/chooseSalonDialog.vue";
import { appLinkList } from "@/static/appLinks";
import { User } from "@fixit/fixit-no-header/src/models";
import CmsHomepageModuleEnum from "@/static/enums/modules/cmsHomepageModuleEnum";

export default {
  name: "HomepageFooter",
  components: { FIcon, chooseSalonDialog },
  data: () => ({
    siteLinks: [
      // { name: "Min profil", query: `profil` },
      { name: "Tidligere besøk", query: "bestillinger" },
    ],
    isMounted: false,
  }),
  props: {
    /**
     * Currently logged in user or empty object
     * @see User
     */
    user: {
      type: [User, Object],
      required: true,
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    navigate(link) {
      link && link != "bonus"
        ? this.$router.replace({ query: { title: link } })
        : this.$router.push(link == "bonus" ? "/bonus" : "/cookies");
      if (link && link != "bonus") {
        this.openLogin();
      }
    },
    normalRedirect(link) {
      window.open(link);
    },
    bookingRedirect() {
      if (this.chainAppointmentButtonOverrideUrl) {
          if (this.chainAppointmentButtonOverrideUrl != '') {
              window.open(this.chainAppointmentButtonOverrideUrl, '_self')
          }
      }
      else {
        this.$router.push('/behandlinger')
      }
    },
    aboutUsNavigation(link) {
      this.$router.push(`/about/${link}`);
    },
    openLogin() {
      this.$emit("openDialog");
    },
    socialName(type) {
      switch (type) {
        case 1:
          return "Instagram";
        case 2:
          return "Facebook";
        case 3:
          return "Snapchat";
        case 4:
          return "YouTube";
        case 5:
          return "TikTok";
      }
    },
    socialIcon(type) {
      switch (type) {
        case 1:
          return "fxt-instagram";
        case 2:
          return "fxt-facebook";
        case 3:
          return "fxt-snapchat";
        case 4:
          return "fxt-youtube";
        case 5:
          return "fxt-tiktok";
      }
    },
  },
  computed: {
    homepageSocials() {
      return this.$store.getters["cmsConfigHomepage/getHomepageSocials"];
    },
    chainAppointmentButtonOverrideText() {
      return this.$store.getters["cmsConfigHomepage/getAppointmentButtonOverrideText"];
    },
    chainAppointmentButtonOverrideUrl() {
      return this.$store.getters["cmsConfigHomepage/getAppointmentButtonOverrideUrl"];
    },
    linkList() {
      return appLinkList();
    },
    useConstrastColors() {
      return this.$store.getters[
        "cmsConfigHomepage/primaryAndSecondaryAreEqual"
      ];
    },
    homepageSocialMediaActivated() {
      return this.$store.getters["cmsConfigHomepage/getModules"].find(
        (m) => m.moduleId == CmsHomepageModuleEnum.HOMEPAGESOCIALMEDIA
      )?.visible;
    },
    // currentSalon() {
    //   if (this.salons.length >= 2 && this.$router.currentRoute.path.includes('about') && this.$router.currentRoute.path.length > 8) return this.$store.getters['salon/getSalonByUrlFriendlyLink'](this.$router.currentRoute.path.split('/')[2])
    //   else if (this.salons.length < 2) return this.salons[0]
    // },
    showDepartments() {
      // if (this.salons.length < 2) return false
      // else if (this.salons.length >= 2 && this.$router.currentRoute.path.includes('about') && this.$router.currentRoute.path.length > 8) return false
      // else return true
      return this.salons.length > 1;
    },
    bookingSalons() {
      let selectedSalon = this.$store.getters['cart/getSelectedSalon']
      if (selectedSalon) {
        return this.salons.filter(x => x.siteId === selectedSalon);
      } else {
        return this.salons
      }
    },
    salons() {
      let sites = [];
      let siteIds = this.$store.getters["salon/getSiteIds"];
      siteIds.forEach((siteId) => {
        sites.push(this.$store.getters["salon/getSalonById"](siteId));
      });
      return sites.filter(x => x.enabled);
    },
    isDraft() {
      return this.$store.getters["cmsConfigHomepage/isDraftMode"];
    },
    singleSalonSoMe() {
      if (!this.showDepartments && this.salons.length) {
        let salon = this.salons[0];
        return {
          instagramEnabled: salon.instagramUrlEnabled,
          facebookEnabled: salon.facebookUrlEnabled,
          snapchatEnabled: salon.snapchatUrlEnabled,
          youtubeEnabled: salon.youTubeUrlEnabled,
          tiktokEnabled: salon.tikTokUrlEnabled,

          instagramUrl: salon.instagramUrl,
          facebookUrl: salon.faceBookUrl,
          snapchatUrl: salon.snapchatUrl,
          youtubeUrl: salon.youTubeUrl,
          tiktokUrl: salon.tikTokUrl,
        };
      } else {
        return {};
      }
    },
    chainName() {
      return this.$store.getters["cmsConfigHomepage/getChainName"];
    },
    customerProgram() {
      let customerPrograms =
        this.$store.getters["cmsConfigHomepage/getActiveCustomerPrograms"];
      return customerPrograms != null &&
        customerPrograms != undefined &&
        customerPrograms.length
        ? customerPrograms[0]
        : null;
    },
  },
};
