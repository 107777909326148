const SettingTypeEnum = Object.freeze({
        INVALID: 0,        
        COLOR1: 1,        
        COLOR2: 2,        
        FONT1: 3,
        FONT2: 4,
        THEMESTYLE: 5,
        BOXCOLOR: 6,
  });
  
  export default SettingTypeEnum;