import axios from 'axios'
export default function TreatmentService(options) {
    this.baseURL= options.env.HOMEPAGE_CORE_API
	this.bookingAPI = options.env.VUE_APP_FIXIT_NO_BOOKING_URL


	  /**
	 * Get treatment categories by chain id.
	 * @param {Int} chainID - chainId
	 */
	  this.GetTreatmentCategories = async function(chainId, siteIds ) {
		const result = await axios.post(this.baseURL + `treatment/get-treatment-categories`, {
			chainId: chainId, 
			siteIds: siteIds
		});
		if (result.data.responseCode === 200) {
			return result.data.result;
		}
		return false;
	}
	 /**
	 * Get treatment by categories.
	 * @param {Int} chainID - chainId
	 * @param {Int} siteId - siteId
	 */
	 this.FetchTreatmentsByCategories = async function(chainId, siteIds) {
		const res = await axios.post(this.bookingAPI + `/api/treatment/get-treatment-categories?chainId=${chainId}`, siteIds);
		if (res.data && res.data.responseCode === 200) {
            return res.data.treatmentCategoryList;
        }
		return false;
	}
    /**
	 * Get treatments by filter.
	 * @param {TreatmentFilter} filter - the filter for treatments
	 */
    this.GetTreatmentsBySiteIds = async function(filter) {
		const result = await axios.post(this.baseURL + `treatment/get-treatments-with-filter`, filter);
		if (result.data.responseCode === 200) {
			return result.data.result;
		}
		return false;
	}

    /**
	 * Get popular treatments by siteIds url.
	 * @param {Array<Int>} siteId - the site id
	 */
	 this.GetPopularTreatmentsBySiteIds = async function(siteIds, chainId) {
		const result = await axios.post(this.baseURL + `treatment/get-popular-treatments-by-siteids/${chainId}`,siteIds);
		if (result.data.responseCode === 200) {
			return result.data.result;
		}
		return false;
	}

   /**
	 * Get treatment by base64 parsed id.
	 * @param {String} id - the base64 id
	 */
  this.GetTreatment = async function(id, chainId, sites){
    const result = await axios.post(this.baseURL + `treatment/get-treatment-by-id/${id}`,
    {
        siteIds: sites,
        chainId: chainId
    }
    );
    if (result.data.responseCode === 200) {
      return result.data.result;
    }
    return null;
  }


}
