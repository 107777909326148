
import FixitDialog from '../../../components/FixitDialog.vue'
import {salonPool} from '../../../helpers/salonPool'

export default {
    components: { FixitDialog },
    props: {
        bundle: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        maxPerCustomerDialog: false,
        soldOutDialog: false,
    }),
    methods: {
        formatPrice() {
            return this.bundle.campaignValue.toFixed(2).replace('.', ',')
        },
        bundleRedirect() {
            
        },
        async addOrRemove(action) {
            let salonsAvailableForCheckout = salonPool(this)
            let selectedProductStock = this.bundle.subscriptionsList;
            let selectedProductStockInSalonsAvailableForCheckout = selectedProductStock
                ?.filter( (p) =>
                    salonsAvailableForCheckout.includes(p.siteId) &&
                    (p.availableAmount >= this.bundle.quantity + 1 ||
                    p.availableAmount == 0)
                ).map((s) => s.siteId);
            if (action === 'add') {
                if (selectedProductStockInSalonsAvailableForCheckout?.length) {
                    if (this.bundle.quantity < this.bundle.maxPerCustomer) {
                        if (this.bundleIsInStock(this.bundle, selectedProductStockInSalonsAvailableForCheckout[0])) {
                            this.$store.dispatch('cart/insertOrUpdateProduct', {
                                siteId: this.$store.getters['cart/getSalonPoolDefaultSiteId'],
                                isCompleted: false,
                                salePrice: this.bundle.campaignValue,
                                quantity: this.bundle.quantity + 1,
                                productId: this.bundle.campaignId.toString(),
                            })
                        }
                    } else {
                        this.maxPerCustomerDialog = true
                    }
                } else {
                    this.soldOutDialog = true
                }
            } else if (action === 'remove') {
                if (this.bundle.quantity - 1 <= 0) {
                    await this.$store.dispatch('cart/deleteShoppingCartItem', {item: this.bundle, type: 'P'})
                } else {
                    this.$store.dispatch('cart/insertOrUpdateProduct', {
                        siteId: this.$store.getters['cart/getSalonPoolDefaultSiteId'],
                        isCompleted: false,
                        salePrice: this.bundle.campaignValue,
                        quantity: this.bundle.quantity - 1,
                        productId: this.bundle.campaignId.toString(),
                    })
                }
            } else {
                await this.$store.dispatch('cart/deleteShoppingCartItem', {item: this.bundle, type: 'P'})
            }
        },
        bundleIsInCart() {
            return this.$store.getters['cart/bundleIsInCart'](this.bundle.campaignId)
        },
        bundleIsInStock(
            bundle,
            siteId
        ) {
            let hasAvailableStock = true;
            /**
             * For each product in the bundle, check if the salon has that subproduct
             * available before adding another to cart.
             */
            for (const prod of bundle.products) {
                if (
                prod.amount * (bundle.quantity + 1) >
                prod.stockCountSalons[siteId]
                ) {
                hasAvailableStock = false;
                }
            }
            return hasAvailableStock;
        }
    }
}
