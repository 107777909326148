export default function EmployeeService(options) {
    this.axios = options.axios
        .create({
            baseURL: options.env.HOMEPAGE_CORE_API
        });

    /**
	 * Get employees by siteIds url.
	 * @param {Array<Int>} siteIds - the site ids
	 */
	 this.GetEmployeesBySiteIds = async function(siteIds) {
		const result = await this.axios.get(`employee/get-employees-by-siteids`, { 
            params: {
                siteIds: siteIds,
            }
        });
		if (result.data.responseCode === 200) {
			return result.data.result;
		}
		return false;
	}

}