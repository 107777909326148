import axios from "axios";
import { axiosRefreshTokenSetup } from "@fixit/utils/src/axiosHandlers";


export default function ({ $config }, inject) {
  var config = $config;
  if(process.client){
    axiosRefreshTokenSetup(axios, 'noredirect', `/refresh-token`);
    axios.defaults.withCredentials = false;
    axios.defaults.headers.common['Application-Source'] = 'WebBooking';
  }

  inject('axios', axios)
}

