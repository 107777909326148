import {salonPool} from '../helpers/salonPool'

export async function addRecEngineProductToCart(context, product) {
    const cartProducts = context.$store.getters['cart/getCartProducts']
      const salonsAvailableForCheckout = salonPool(context); // salons that can sell the cart content
      let productPrice, selectedProductStockInSalonsAvailableForCheckout = [];
      const productId = product.urlSafe?.split('/')[1];
      const isBundle = product.campaignValue;
      const quantity = cartProducts.find((x) => x.base64Id === productId)
        ? cartProducts.find((x) => x.base64Id === productId)?.quantity
        : 0;
      //Bundle spesific logic
      if (isBundle) {
        productPrice = product.campaignValue;
        selectedProductStockInSalonsAvailableForCheckout = product.stockAmount
          ?.filter(
            (p) =>
              salonsAvailableForCheckout.includes(p.siteId) &&
              (p.productStockAmount >= (quantity || 0)  + 1 ||
                p.productStockAmount == 0) &&
                (quantity || 0)  + 1 <= 5 //TODO: maxpercustomer
          )
          .map((s) => s.siteId) || [];
        //Product spesific logic
      } else {
        productPrice = product.price;
        selectedProductStockInSalonsAvailableForCheckout = product.stockAmount
          ?.filter(
            (p) =>
              salonsAvailableForCheckout.includes(p.siteId) &&
              p.productStockAmount >= (quantity || 0) + 1
          )
          .map((s) => s.siteId) || [];
      }
      if (selectedProductStockInSalonsAvailableForCheckout?.length) {
        if (
          !isBundle ||
          (isBundle && (quantity || 0)  < 5) //TODO: maxpercustomer
        ) {
          await context.$store.dispatch('cart/insertOrUpdateProduct', {
              siteId: selectedProductStockInSalonsAvailableForCheckout[0],
              isCompleted: false,
              salePrice: productPrice || 0,
              quantity: (quantity || 0)  + 1,
              productId: productId || '',
            })
          if (!context.addedDialog) {
            return 'addedDialogOpen'
          }
        } else {
            return 'maxPerCustomerDialog'
        }
      } else {
        return 'outOfStockDialog'
      }
}

export async function addRecEngineTreatmentToCart(context, treatment) {

}


