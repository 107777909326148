export function salonPool(context, calculateUnavailableProducts = false) {
    const cartProducts  = context.$store.getters['cart/getCartProducts'] || [];
    const allDepartments = context.$store.getters["salon/getSalons"];
    const activeSites = allDepartments
    .filter((x) => x.webshopActive)
    .map((x) => x.siteId);
    // const activeSites = [101, 1521]
    const bundles = getBundles(context, activeSites)

    /**
     * Get data for salonpool calculation
     *
     * productsStockSiteIds: List of siteIds which can fulfill a product. X products and X siteIds means that site can fulfil all products
     * unavailableProducts / unavailableBundles: List of products/bundles that are unavailable. Not enough in stock for salon to fulfill order
     * productStockByActiveSites / bundlesStockByActiveSites: Stock data for webshop active sites before salon pool calculation.
     */
    const data = getProductsStockAndUnavailable(cartProducts, bundles, activeSites);
    const productsStockSiteIds = data.productsStockSiteIds;
    const unavailableProducts = data.unavailableProducts;
    const unavailableBundles = data.unavailableBundles;
    const productStockByActiveSites = data.productStockByActiveSites;
    const bundlesStockByActiveSites = data.bundlesStockByActiveSites;

    if (cartProducts.length || bundles.length) {
      /**
       * Calculate salon pool.
       * (A list of unique site ids which can deliver all products in cart)
       */
      const pool = calculateSalonPool(cartProducts, bundles, productsStockSiteIds);

      /**
       * When loading cart on page load, we should also check if products has become
       * unavailable in the time between user added to cart, and the user starts the
       * checkout process.
       */
      if (calculateUnavailableProducts) {
        calculateUnavailable(
            context,
            pool,
            unavailableProducts,
            unavailableBundles,
            productStockByActiveSites,
            bundlesStockByActiveSites,
            (cartProducts.length ? cartProducts : []),
            bundles,
            activeSites
        );
      }
      context.$store.commit('cart/setSalonPoolDefaultSiteId', pool[0])
      return pool;
    }
    return activeSites;
}

function getProductsStockAndUnavailable(products, bundles, activeSites) {
const productStockByActiveSites = [];
const bundlesStockByActiveSites = [];
const unavailableProducts = []; //List of products that are unavailable
const unavailableBundles = []; //List of bundles that are unavailable
const productsStock = []; //List of siteIds which can fulfill a product. X products and X siteIds means that site can fulfil all products
//PRODUCTS
if (products.length) {
    for (const i in products) {
    const product = products[i];
    product.stockAmount
        .filter((s) => activeSites.includes(s.siteId))
        .forEach((s) => {
        productStockByActiveSites.push(s);
        });
    const stock = product.stockAmount.filter(
        (s) =>
        activeSites.includes(s.siteId) &&
        s.productStockAmount >= product.quantity
    );
    if (stock.length) {
        stock.forEach((s) => productsStock.push(s.siteId));
    } else {
        unavailableProducts.push(product);
    }
    }
}

//BUNDLES
if (bundles.length) {
    for (const j in bundles) {
    const bundle = bundles[j];
    bundle.subscriptionsList
        .filter((s) => activeSites.includes(s.siteId))
        .forEach((s) => {
        bundlesStockByActiveSites.push(s);
        });
    const bundlestock = bundle.subscriptionsList.filter(
        (s) =>
        activeSites.includes(s.siteId) &&
        s.availableAmount >= bundle.quantity
    );

    if (bundlestock.length) {
        bundlestock.forEach((s) => productsStock.push(s.siteId));
    } else {
        unavailableBundles.push(bundle);
    }
    }
}

return {
    productsStockSiteIds: productsStock,
    unavailableBundles: unavailableBundles,
    unavailableProducts: unavailableProducts,
    productStockByActiveSites: productStockByActiveSites,
    bundlesStockByActiveSites: bundlesStockByActiveSites,
};
}

function calculateSalonPool(products, bundles, productsStockSiteIds) {
const counts = {};
productsStockSiteIds.forEach(function (x) {
    counts[x] = (counts[x] || 0) + 1;
});
return [...new Set(productsStockSiteIds)].filter(
    (x) => counts[x] == products.length + bundles.length
);
}

function calculateUnavailable(
context,
pool,
unavailableProducts,
unavailableBundles,
productStockByActiveSites,
bundlesStockByActiveSites,
cartProducts, 
cartBundles,
activeSites
) {

const unavailable = [];
/**
 * Product/bundle stock of products from sites which ARE NOT in the salon pool.
 */
const productStockNotInPool = productStockByActiveSites.filter(
    (x) => !pool.includes(x.siteId)
);
const bundleStockNotInPool = bundlesStockByActiveSites.filter(
    (x) => !pool.includes(x.siteId)
);

/**
 * Product/bundle stock of products from sites which ARE in the salon pool
 */
const productInSalonPool = productStockByActiveSites
    .filter((x) => pool.includes(x.siteId))
    .map((x) => x.productId);

const bundleInSalonPool = bundlesStockByActiveSites
    .filter((x) => pool.includes(x.siteId))
    .map((x) => x.campaignId);

/**
 * Since there could be duplicate productIds in the product list (one per siteId), we need to check if each product
 * that isn't in the salon pool doesn't have a duplicate which actually exists there.
 */
productStockNotInPool.forEach(function (x) {
    if (!productInSalonPool.includes(x.productId)) {
    const product = cartProducts.find((p) => p.id === x.productId);
    if (!unavailableProducts.map((x) => x.id).includes(x.productId)) {
        product ? unavailableProducts.push(product) : null;
    }
    }
});
bundleStockNotInPool.forEach(function (x) {
    if (!bundleInSalonPool.includes(x.campaignId)) {
    const bundle = cartBundles.find(
        (p) => p.campaignId === x.campaignId
    );
    if (
        !unavailableBundles.map((b) => b.campaignId).includes(x.campaignId)
    ) {
        bundle ? unavailableBundles.push(bundle) : null;
    }
    }
});

/**
 * For each of the unavailable products:
 *    - Find the highest available stock in the salon pool
 *    - Either set new quantity to the highest stock, or remove the
 *      product completely from cart
 */
if (unavailableProducts.length) {
    unavailableProducts.forEach((p) => {
    const closestStock = activeSites.length
        ? p.stockAmount
            .filter((s) => activeSites.includes(s.siteId))
            .map((x) => {
            return {
                stock: x?.productStockAmount || 0,
                siteId: x.siteId,
            };
            })
        : [];

    const selected = closestStock.find(
        (x) => x.stock === Math.max(...closestStock.map((x) => x.stock))
    ) || { stock: 0, siteId: 0 };

    unavailable.push({
        productId: p.id,
        price: p.price,
        siteId: selected.siteId,
        qty:
        p.quantity >= selected.stock && selected.stock !== 0
            ? selected.stock
            : 0,
    });
    });
}

if (unavailableBundles.length) {
    unavailableBundles.forEach((b) => {
    const closestStock = activeSites.length
        ? b.subscriptionsList
            .filter((s) => activeSites.includes(s.siteId))
            .map((x) => {
            return { stock: x?.availableAmount || 0, siteId: x.siteId };
            })
        : [];
    const selected = closestStock.find(
        (x) => x.stock === Math.max(...closestStock.map((x) => x.stock))
    ) || { stock: 0, siteId: 0 };
    unavailable.push({
        productId: String(b.campaignId),
        price: b.campaignValue,
        siteId: selected.siteId,
        qty:
        b.quantity >= selected.stock && selected.stock !== 0
            ? selected.stock
            : 0,
    });
    });
}
context.$store.commit('cart/setUnavailableProducts', unavailable)
}

function getBundles(context, activeSites) {
const bundles = context.$store.getters['cart/getCartBundles'] || []

const filteredBundles = bundles.filter((b) => {
    const subs = b.subscriptionsList.filter((s) =>
    activeSites.includes(s.siteId)
    );

    subs.forEach((s) => {
    const stocks = [];
    b.products.forEach((p) => {
        const productStock = p.stockCountSalons[s.siteId] ?? 0;
        stocks.push(productStock);
    });
    if (s.availableAmount == 0 || s.availableAmount > Math.min(...stocks)) {
        s.availableAmount = Math.min(...stocks);
    } else if (Math.min(...stocks) > b.maxPerCustomer) {
        s.availableAmount = b.maxPerCustomer;
    }
    });

    b.subscriptionsList = subs;
    return b;
});
return filteredBundles || [];
}