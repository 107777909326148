import { render, staticRenderFns } from "./HomepageLayout.vue?vue&type=template&id=2453776f"
import script from "./HomepageLayout.vue?vue&type=script&lang=js"
export * from "./HomepageLayout.vue?vue&type=script&lang=js"
import style0 from "./HomepageLayout.vue?vue&type=style&index=0&id=2453776f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartTabItem: require('/salonhomepage/client/components/CartTabItem.vue').default,HomepageFooter: require('/salonhomepage/client/components/HomepageFooter.vue').default,RecEngineModule: require('/salonhomepage/client/components/RecEngineModule.vue').default,FixitDialog: require('/salonhomepage/client/components/FixitDialog.vue').default})
