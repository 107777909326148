import ReceiptService from "../../services/receiptService"

const initialState = {
    receipt: null
}

export const state = () => ({
    ...initialState
})
const getters = {
    getReceipt: state => state.receipt,
}

const mutations = {
    setReceipt( state, value ) {
        state.receipt = value
    },
    RESET_MODULE (state) { // To reset the entire module
        Object.assign(state, initialState)
    }
}
const actions = {
    async fetchReceiptById( { commit }, receiptId) {
        const receiptService = new ReceiptService({axios: this.$axios, env: this.$config})
        var result = await receiptService.fetchReceiptById( receiptId )
        if ( result && result.status === 200 && result.data.result) {
            return result.data.result
        }
    },
    fetchMock( { commit }, receiptId ) {
        const receiptService = new ReceiptService( { axios: this.$axios, env: this.$config } )
        var result = receiptService.fetchReceiptMock( receiptId )
        commit('setReceipt', result)

    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
