
import CmsHomepageModuleEnum from "@/static/enums/modules/cmsHomepageModuleEnum";
import RecommendationBox from "@fixit/v-recommendation-box";
import { User } from "@fixit/fixit-no-header/src/models";
import FixitDialog from "../components/FixitDialog.vue";
import CartList from "../pages/cart/components/CartList.vue";
import AddToCart from "../pages/cart/components/AddToCart.vue";
import { addRecEngineProductToCart } from "../helpers/recEngineHelper";
import {
  ItemType,
  Location,
  Channel,
} from "@fixit/v-recommendation-box/src/enums";
export default {
  components: {
    RecommendationBox,
    FixitDialog,
    CartList,
    AddToCart,
  },
  name: "RecEngineModule",
  props: {
    boxTitle: {
      required: true,
      default: "",
    },
    wrapperClasses: {
      required: false,
      default: "",
    },
    siteIds: {
      required: false,
      default: () => {
        return [];
      },
    },
    treatmentIds: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    itemQty: {
      required: false,
      default: 4,
    },
    location: {
      required: false,
      default: Location.HOME_PAGE,
    },
    presentation: {
      required: false,
      default: "carousel",
    },
    hideButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    employeeId: {
      required: false,
      default: "",
    },
    channel: {
      required: false,
      default: Channel.HomePage,
    },
    itemType: {
      required: true,
      default: ItemType.T,
    },
  },
  layout: "HomepageLayout",
  data: () => ({
    maxPerCustomerDialog: false,
    outOfStockDialog: false,
    currentSelectedTreatment: null,
    addedKey: 0,
    itemsFetched: false,
    itemTypeEnum: ItemType,
    locationEnum: Location,
    channelEnum: Channel,
  }),
  computed: {
    homepageModules() {
      var modules = this.$store.getters["cmsConfigHomepage/getModules"];
      let showPrices = modules.find(
        (m) => m.moduleId == CmsHomepageModuleEnum.PRICES
      )?.visible;
      let prices = true;
      if (showPrices != undefined && showPrices != null) prices = showPrices;
      return {
        treatments: modules.find(
          (m) => m.moduleId == CmsHomepageModuleEnum.HOMEPAGETREATMENTS
        )?.visible,
        prices: prices,
        products:
          modules.find(
            (m) => m.moduleId == CmsHomepageModuleEnum.SUPPLIERBRANDS
          )?.visible &&
          this.$store.getters["cmsConfigHomepage/getWebshopActiveStatus"],
      };
    },
    bookingActive() {
      return this.$store.getters['salon/bookingEnabledForSite'](this.$store.getters['cart/getSelectedSalon'])
    },
    productIds() {
      return this.$store.getters["cart/getCartProducts"]
        .map((x) => x.base64Id)
        .concat(
          this.$store.getters["cart/getCartBundles"].map((x) => x.base64Id)
        );
    },
    chainId() {
      return this.$store.getters["cmsConfigHomepage/getChainId"];
    },
    isDraft() {
      return this.$store.getters["cmsConfigHomepage/isDraftMode"];
    },
    chainAppointmentButtonOverrideUrl() {
      return this.$store.getters[
        "cmsConfigHomepage/getAppointmentButtonOverrideUrl"
      ];
    },
    chainAppointmentButtonOverrideText() {
      return this.$store.getters[
        "cmsConfigHomepage/getAppointmentButtonOverrideText"
      ];
    },
    overrideText() {
      if (this.siteIds.length == 1 && this.siteIds[0] !== 0) {
        let salon = this.$store.getters["salon/getSalonById"](this.siteIds[0]);
        return this.chainAppointmentButtonOverrideText
          ? this.chainAppointmentButtonOverrideText
          : salon.appointmentButtonOverrideText
          ? salon.appointmentButtonOverrideText
          : null;
      } else {
        return null;
      }
    },
    overrideUrl() {
      if (this.selectedSalon) {
        return this.selectedSalon.appointmentButtonOverrideUrl
          ? this.selectedSalon.appointmentButtonOverrideUrl
          : null;
      } else {
        return null;
      }
    },
    overrideText() {
      var salonSiteId = this.$store.getters["cart/getSelectedSalon"];
      if (salonSiteId) {
        let salon = this.$store.getters["salon/getSalonById"](salonSiteId);
        return salon.appointmentButtonOverrideText
          ? salon.appointmentButtonOverrideText
          : null;
      } else {
        return null;
      }
    },
    user() {
      return new User(this.$store.getters["app/get"]("user"));
    },
  },

  methods: {
    GoToTreatment(treatment) {
      this.$router.push(`/behandlinger/${treatment.urlSafe}`);
    },
    async addOrRemove(treatment) {
      this.addedKey += 1;
      this.currentSelectedTreatment = treatment;
    },
    moreTreatment() {
      this.$router.push(`/Behandlinger`);
    },
    setFetched(val) {
      this.$emit("itemsFetched", val);
      this.itemsFetched = val;
    },
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    moreProduct() {
      window.open(`${this.$config.PORTAL_URL}/nettbutikk/${this.chainId}/`);
    },
    productRedirect(item) {
      if (!this.isDraft) {
        window.open(
          this.$config.PORTAL_URL +
            `/nettbutikk/${this.chainId}/produkt/${item.urlSafe}/`,
          "_blank"
        );
      }
    },
    setFetched(val) {
      this.$emit("itemsFetched", val);
      this.itemsFetched = val;
    },
    async addToCartRecEngine(item) {
      let res = await addRecEngineProductToCart(this, item);
      switch (res) {
        case "addedDialogOpen":
          this.$root.$emit("openAddedToCartDialog", ItemType.P);
          break;
        case "addedDialogClose":
          this.$root.$emit("closeAddedToCartDialog");
          break;
        case "maxPerCustomerDialog":
          this.maxPerCustomerDialog = true;
          break;
        case "outOfStockDialog":
          this.outOfStockDialog = true;
          break;
      }
    },
  },
};
