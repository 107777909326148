
import CartBundle from "./CartBundle.vue";
import CartProduct from "./CartProduct.vue";
import CartTreatment from "./CartTreatment.vue";
import { CmsHomepageModuleEnum } from "@/static/enums/index.js";
import { User } from "@fixit/fixit-no-header/src/models";
import {
  ItemType,
  Location,
  Channel,
} from "@fixit/v-recommendation-box/src/enums";
export default {
  components: {
    CartBundle,
    CartProduct,
    CartTreatment,
    RecEngineModule: () => import("../../../components/RecEngineModule.vue"),
  },
  props: {
    isAddedToCartDialog: {
      required: false,
      default: false,
    },
  },
  data: () => ({
    itemType: ItemType,
    location: Location,
    channel: Channel,
  }),
  computed: {
    cart() {
      return this.$store.getters["cart/getCart"];
    },
    cartSalon() {
      if (this.cart && this.cart.siteId) {
        return this.$store.getters["salon/getSalonById"](this.cart.siteId)
      } else {
        return this.$store.getters["salon/getSalonById"](this.$store.getters['cart/getSelectedSalon'] || this.$store.getters['cart/getPreviousSelectedSalon'] || this.$store.getters['cart/getcart'])
      }
    },
    cartCount() {
      return this.$store.getters["cart/getCartCount"];
    },
    cartTreatments() {
      return this.$store.getters["cart/getCartTreatments"];
    },
    cartTreatmentIds() {
      return this.$store.getters["cart/getCartTreatments"].map(
        (x) => x.urlSafe
      );
    },
    cartProducts() {
      return this.$store.getters["cart/getCartProducts"];
    },
    cartBundles() {
      return this.$store.getters["cart/getCartBundles"];
    },
    cartType() {
      return this.cartTreatments.length &&
        (this.cartBundles.length || this.cartProducts.length)
        ? ItemType.C
        : !this.cartTreatments.length && (this.cartBundles.length || this.cartProducts.length)
        ? ItemType.P
        : ItemType.T;
    },
    treatmentsTotalPrice() {
      return this.$store.getters["cart/getTotalTreatmentsPrice"];
    },
    productsTotalPrice() {
      return this.$store.getters["cart/getTotalProductsPrice"];
    },
    productDiscounts() {
      return this.$store.getters["cart/getTotalProductsDiscounts"];
    },
    siteIds() {
      return this.$store.getters["salon/getSiteIds"];
    },
    user() {
      return new User(this.$store.getters["app/get"]("user"));
    },
    showPrice() {
      var modules = this.$store.getters["cmsConfigHomepage/getModules"];
      let moduleShowPricesValue = modules.find(
        (m) => m.moduleId == CmsHomepageModuleEnum.PRICES
      )?.visible;
      let prices = true;
      if (moduleShowPricesValue != undefined && moduleShowPricesValue != null)
        prices = moduleShowPricesValue;
      return prices;
    },
  },
  methods: {
    formatPrice(price) {
      return price.toFixed(2).replace(".", ",");
    },
    closeAddedDialog() {
      this.$root.$emit("closeAddedToCartDialog");
    },
    redirect(item) {
      if (item.type == ItemType.T) {
        this.$router.push(`/Behandlinger/${item.urlSafe}`);
      } else {
        window.open(
          this.$config.PORTAL_URL +
            `/nettbutikk/${this.cart.chainId}/produkt/${item.urlSafe}/`,
          "_blank"
        );
      }
    },
  },
};
