export default function SalonService(options) {
    this.axios = options.axios
        .create({
            baseURL: options.env.HOMEPAGE_CORE_API
        });

    /**
	 * Get salonService url.
	 * @param {Int} homepageId - the chain Id
	 */
    this.GetSalonsByHomepageId = async function(homepageId, ) {
		const result = await this.axios.get(`department/get-salons`, {
            params: {
                homepageId: homepageId,
            }
        });
		if (result.data.responseCode == 200) {
			return result.data.result;
		}
		return false;
	}
}
