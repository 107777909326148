import SalonService from "../../services/salonService";
import ProfileSvc from '@fixit/fixit-no-header/src/services/profile-svc'

export const state = () => ({
  salons: [],
  isSingleSite: false,
  favoriteSalons: []
});
const getters = {
  getSalons: (state) => state.salons,
  getSiteIds: (state) => state.salons.map((x) => x.siteId),
  getSalonById: (state) => (id) => state.salons.find((salon) => salon.siteId === id),
  getHideEmployees: (state) => (id) => state.salons.find((salon) => salon.siteId === id).hideEmployees,
  getSalonByUrlFriendlyLink: (state) => (id) => state.salons.find((salon) => salon.urlFrendlyLink === id),
  getSalonPhoneNumber: (state) => (id) => state.salons.find((salon) => salon.siteId === id),
  getFavoriteSalons: (state) => state.favoriteSalons,
  getFavoriteSalonById: (state) => (id) => state.favoriteSalons.find(x => x.siteId === id),
  getIsSingleSite: (state) => state.isSingleSite,
  bookingEnabledForSite: (state) => (id) => {
    if (id && id > 0) {
      let salon = state.salons.find(x => x.siteId === id)
      return salon ? salon.enabled : false
    } else {
      let enabledSalons = state.salons.map(x => x.enabled)
      return enabledSalons && enabledSalons.length >= 1
    }
  }
};

const mutations = {
  setSalons(state, value) {
    state.salons = value;
    if (state.salons.length === 1) {
      state.isSingleSite = true
    }
  },
  setFavoriteSalons(state, value) {
    state.favoriteSalons = value
  }
};
const actions = {
  async fetchSalons({ commit, rootGetters }) {
    const salonService = new SalonService({
      axios: this.$axios,
      env: this.$config,
    });
    const homepageId = rootGetters["cmsConfigHomepage/getHomepageId"];
    if (homepageId !== null) {
      let result = await salonService.GetSalonsByHomepageId(homepageId);

      commit("setSalons", result);
      return true;
    }
    return false;
  },
  async fetchFavoriteSalons({commit, rootGetters}) {
    const service = await ProfileSvc(this.$axios)
    const chainId = rootGetters["cmsConfigHomepage/getChainId"];
    let res = await service.GetMySalons(this.$config.VUE_APP_FIXIT_NO_BOOKING_URL, 0, chainId, false)
    if (res.length) {
      commit('setFavoriteSalons', res[0].chainSites)
    }
  },
  async updateFavoriteSalons({commit, state}, siteId) {
    const service = await ProfileSvc(this.$axios)
    const isFavorite = state.favoriteSalons.find(x => x.siteId === siteId) ? '0' : '1'
    return await service.UpdateMySalons(this.$config.VUE_APP_FIXIT_NO_BOOKING_URL, siteId.toString(), isFavorite)
  }
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
