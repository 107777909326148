import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b0e73614 = () => interopDefault(import('../client/pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _6280de0a = () => interopDefault(import('../client/pages/app/index.vue' /* webpackChunkName: "pages/app/index" */))
const _062dfb62 = () => interopDefault(import('../client/pages/behandlinger/index.vue' /* webpackChunkName: "pages/behandlinger/index" */))
const _137959f0 = () => interopDefault(import('../client/pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _682249ed = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _13e7bdf8 = () => interopDefault(import('../client/pages/cookies/index.vue' /* webpackChunkName: "pages/cookies/index" */))
const _17cc8a0d = () => interopDefault(import('../client/pages/giftcard/index.vue' /* webpackChunkName: "pages/giftcard/index" */))
const _684995e0 = () => interopDefault(import('../client/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _66342e61 = () => interopDefault(import('../client/pages/receipt/index.vue' /* webpackChunkName: "pages/receipt/index" */))
const _63b43f98 = () => interopDefault(import('../client/pages/cart/components/AddToCart.vue' /* webpackChunkName: "pages/cart/components/AddToCart" */))
const _6a64e458 = () => interopDefault(import('../client/pages/cart/components/AddToCartBtn.vue' /* webpackChunkName: "pages/cart/components/AddToCartBtn" */))
const _6edfff16 = () => interopDefault(import('../client/pages/cart/components/CartBundle.vue' /* webpackChunkName: "pages/cart/components/CartBundle" */))
const _30755c49 = () => interopDefault(import('../client/pages/cart/components/CartFavoriteIcon.vue' /* webpackChunkName: "pages/cart/components/CartFavoriteIcon" */))
const _303db94a = () => interopDefault(import('../client/pages/cart/components/CartInfoIcon.vue' /* webpackChunkName: "pages/cart/components/CartInfoIcon" */))
const _165d2032 = () => interopDefault(import('../client/pages/cart/components/CartList.vue' /* webpackChunkName: "pages/cart/components/CartList" */))
const _2571e28b = () => interopDefault(import('../client/pages/cart/components/CartProduct.vue' /* webpackChunkName: "pages/cart/components/CartProduct" */))
const _6ac81094 = () => interopDefault(import('../client/pages/cart/components/CartTreatment.vue' /* webpackChunkName: "pages/cart/components/CartTreatment" */))
const _7439ff6b = () => interopDefault(import('../client/pages/receipt/components/ReceiptBottom.vue' /* webpackChunkName: "pages/receipt/components/ReceiptBottom" */))
const _6e20ba6e = () => interopDefault(import('../client/pages/receipt/components/ReceiptInfo.vue' /* webpackChunkName: "pages/receipt/components/ReceiptInfo" */))
const _7065a712 = () => interopDefault(import('../client/pages/receipt/components/ReceiptPaymentMethod.vue' /* webpackChunkName: "pages/receipt/components/ReceiptPaymentMethod" */))
const _68a91a56 = () => interopDefault(import('../client/pages/receipt/components/ReceiptSiteInfo.vue' /* webpackChunkName: "pages/receipt/components/ReceiptSiteInfo" */))
const _1a4f47d4 = () => interopDefault(import('../client/pages/receipt/components/ReceiptSummary.vue' /* webpackChunkName: "pages/receipt/components/ReceiptSummary" */))
const _81fa2c62 = () => interopDefault(import('../client/pages/receipt/components/TheReceipt.vue' /* webpackChunkName: "pages/receipt/components/TheReceipt" */))
const _2857db71 = () => interopDefault(import('../client/pages/receipt/data/mock.js' /* webpackChunkName: "pages/receipt/data/mock" */))
const _7e904b78 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _071e14e1 = () => interopDefault(import('../client/pages/about/_id/index.vue' /* webpackChunkName: "pages/about/_id/index" */))
const _29f8df70 = () => interopDefault(import('../client/pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _b26cc6c2 = () => interopDefault(import('../client/pages/about/_id/_employee/index.vue' /* webpackChunkName: "pages/about/_id/_employee/index" */))
const _3a3c1c44 = () => interopDefault(import('../client/pages/behandlinger/_treatment/_id.vue' /* webpackChunkName: "pages/behandlinger/_treatment/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _b0e73614,
    name: "about"
  }, {
    path: "/app",
    component: _6280de0a,
    name: "app"
  }, {
    path: "/behandlinger",
    component: _062dfb62,
    name: "behandlinger"
  }, {
    path: "/bonus",
    component: _137959f0,
    name: "bonus"
  }, {
    path: "/cart",
    component: _682249ed,
    name: "cart"
  }, {
    path: "/cookies",
    component: _13e7bdf8,
    name: "cookies"
  }, {
    path: "/giftcard",
    component: _17cc8a0d,
    name: "giftcard"
  }, {
    path: "/news",
    component: _684995e0,
    name: "news"
  }, {
    path: "/receipt",
    component: _66342e61,
    name: "receipt"
  }, {
    path: "/cart/components/AddToCart",
    component: _63b43f98,
    name: "cart-components-AddToCart"
  }, {
    path: "/cart/components/AddToCartBtn",
    component: _6a64e458,
    name: "cart-components-AddToCartBtn"
  }, {
    path: "/cart/components/CartBundle",
    component: _6edfff16,
    name: "cart-components-CartBundle"
  }, {
    path: "/cart/components/CartFavoriteIcon",
    component: _30755c49,
    name: "cart-components-CartFavoriteIcon"
  }, {
    path: "/cart/components/CartInfoIcon",
    component: _303db94a,
    name: "cart-components-CartInfoIcon"
  }, {
    path: "/cart/components/CartList",
    component: _165d2032,
    name: "cart-components-CartList"
  }, {
    path: "/cart/components/CartProduct",
    component: _2571e28b,
    name: "cart-components-CartProduct"
  }, {
    path: "/cart/components/CartTreatment",
    component: _6ac81094,
    name: "cart-components-CartTreatment"
  }, {
    path: "/receipt/components/ReceiptBottom",
    component: _7439ff6b,
    name: "receipt-components-ReceiptBottom"
  }, {
    path: "/receipt/components/ReceiptInfo",
    component: _6e20ba6e,
    name: "receipt-components-ReceiptInfo"
  }, {
    path: "/receipt/components/ReceiptPaymentMethod",
    component: _7065a712,
    name: "receipt-components-ReceiptPaymentMethod"
  }, {
    path: "/receipt/components/ReceiptSiteInfo",
    component: _68a91a56,
    name: "receipt-components-ReceiptSiteInfo"
  }, {
    path: "/receipt/components/ReceiptSummary",
    component: _1a4f47d4,
    name: "receipt-components-ReceiptSummary"
  }, {
    path: "/receipt/components/TheReceipt",
    component: _81fa2c62,
    name: "receipt-components-TheReceipt"
  }, {
    path: "/receipt/data/mock",
    component: _2857db71,
    name: "receipt-data-mock"
  }, {
    path: "/",
    component: _7e904b78,
    name: "index"
  }, {
    path: "/about/:id",
    component: _071e14e1,
    name: "about-id"
  }, {
    path: "/news/:id",
    component: _29f8df70,
    name: "news-id"
  }, {
    path: "/about/:id/:employee",
    component: _b26cc6c2,
    name: "about-id-employee"
  }, {
    path: "/behandlinger/:treatment/:id?",
    component: _3a3c1c44,
    name: "behandlinger-treatment-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
