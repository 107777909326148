const CmsHomepageModuleEnum = Object.freeze({
    CHAINLOGO: 1,
    CHAINDEPARTMENTS: 2,
    CHAINBANNER: 3,
    HOMEPAGELOGO: 4,
    HOMEPAGEIMAGECAROUSEL: 5,
    HOMEPAGEABOUT: 6,
    HOMEPAGEEMPLOYEES: 7,
    HOMEPAGETREATMENTS: 8,
    HOMEPAGEARTICLES: 9,
    HOMEPAGEREVIEWS: 10,
    HOMEPAGECONTACT: 11,
    HOMEPAGESOCIALMEDIA: 12,
    IMAGESINSTAGRAM: 13,
    SUPPLIERBRANDS: 14,
    HOMEPAGEGIFTCARDS: 15,
    PRICES: 16,
  });
  
  export default CmsHomepageModuleEnum;