

export default {
    data() {
        return {
            menu: false
        }
    },
    methods: {
        onMenuButtonClick() {
            this.$router.push(`/cart`);
        },
    },
    computed: {
        cartProductsCount() {
            return this.$store.getters['cart/getCartCount']
        }
    }
}
