import receiptData from '../pages/receipt/data/mock.js'

export default function ReceiptService( options ) {
    this.axios = options.axios
        .create({
            baseURL: options.env.HOMEPAGE_CORE_API
        });

    this.fetchReceiptById = async function ( receiptId ) {
        const result = await this.axios.get(`receipt/get-receipt-by-id?receiptId=${receiptId}`);
        return result;
    }

    this.fetchReceiptMock = function ( receiptId ) {
        const result = receiptData  
        return result;
    }

}