import { render, staticRenderFns } from "./RecEngineModule.vue?vue&type=template&id=59ce098f"
import script from "./RecEngineModule.vue?vue&type=script&lang=js"
export * from "./RecEngineModule.vue?vue&type=script&lang=js"
import style0 from "./RecEngineModule.vue?vue&type=style&index=0&id=59ce098f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FixitDialog: require('/salonhomepage/client/components/FixitDialog.vue').default})
