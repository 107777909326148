export default function NewsService(options) {
    this.axios = options.axios
        .create({
            baseURL: options.env.HOMEPAGE_CORE_API
        });

	/**
	 * Get a list of news for all the sites passed as parameters.
     * @param {Array<Int>} siteIds - the departments for which the news will be retrieved from
	 */
	 this.GetNewsBySiteIds = async function(siteIds) {
		const result = await this.axios.post(`news/get-news-by-siteids`, siteIds);
		// if (result.data.responseCode === 200) {
			if (result.data.responseCode === 200) {
			return result.data.result;
		}
		return false;
	}
	
    /**
	 * Get news url.
	 * @param {Int} newsId - the news id
	 */
    this.GetNewsById = async function(siteIds, newsId) {
		const result = await this.axios.post(`news/get-news-by-id/${newsId}`, siteIds );
		if (result.data.responseCode === 200) {
			return result.data.result;
		}
		return null;
	}

}