import NewsService from "../../services/newsService"

export const state = () => ({
  news: []
})
const getters = {
  getNews: state => state.news,
  getNewsById: state => id => state.news.find(news => news.id === id),
}

const mutations = {
  setNews(state, value) {
    state.news = value
  }
}
const actions = {
  async fetchNewsBySiteIds({commit, rootGetters}) {
    const newsService = new NewsService({axios: this.$axios, env: this.$config})
    const siteIds = rootGetters['salon/getSiteIds'];
    if (siteIds !== null && siteIds.length > 0) {
      let result = await newsService.GetNewsBySiteIds(siteIds)
      commit('setNews', result)
      return true
    }
    return false
  },
  async getNewsById({commit, rootGetters}, newsId) {
    const newsService = new NewsService({axios: this.$axios, env: this.$config})
    const siteIds = rootGetters['salon/getSiteIds'];
    let news = await newsService.GetNewsById(siteIds, newsId)
    if (news != null) {
      return news
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
