import ProfileSvc from '@fixit/fixit-no-header/src/services/profile-svc'
import _ from 'lodash-es';

export const state = () => ({
    favoriteProducts: [],
})
const getters = {
    getFavoriteProducts: state => state.favoriteProducts
}

const mutations = {
    setFavoriteProducts(state, value) {
        state.favoriteProducts = value
    }
}
const actions = {
    async fetchFavoriteProducts({commit, rootGetters}) {
        const service = await ProfileSvc(this.$axios)
        var siteIds = rootGetters['salon/getSiteIds'];
        var result = await service.GetFavoriteProducts(this.$config.VUE_APP_FIXIT_NO_BOOKING_URL, siteIds)
        commit('setFavoriteProducts', result)
    },
    async updateFavoriteProducts({}, product) {
        const service = await ProfileSvc(this.$axios)
        return await service.UpdateFavoriteProducts(
            this.$config.VUE_APP_FIXIT_NO_BOOKING_URL,
            product.productId, product.siteId
        )
    }
}

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}
