import { render, staticRenderFns } from "./CartBundle.vue?vue&type=template&id=7394e228&scoped=true"
import script from "./CartBundle.vue?vue&type=script&lang=js"
export * from "./CartBundle.vue?vue&type=script&lang=js"
import style0 from "./CartBundle.vue?vue&type=style&index=0&id=7394e228&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7394e228",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FixitDialog: require('/salonhomepage/client/components/FixitDialog.vue').default})
