
import FixitDialog from "../../../components/FixitDialog.vue";
import { sortSalonListByUserLocation } from "@fixit/fixit-no-header/src/utils/location";
import { User } from "@fixit/fixit-no-header/src/models";
import { ItemType } from "@fixit/v-recommendation-box/src/enums";
export default {
  components: {
    FixitDialog,
  },
  name: "AddToCart",
  props: {
    treatment: {
      type: Object,
      required: false,
    },
    isRecEngine: {
      required: false,
      type: Boolean,
    },
    employeeId: {
      required: false,
      default: null,
    },
  },
  data: () => ({
    salons: [],
    selectSalonDialog: false,
    illegalTreatmentCombinationDialog: false,
    loading: false,
  }),
  computed: {
    isCheckout() {
      return this.$route.path === "/cart";
    },
    user() {
      return new User(this.$store.getters["app/get"]("user"));
    },
  },
  methods: {
    /**
     * Set the selected salon siteId to localstorage and cart state.
     * Then add treatment to cart
     */
    async selectSalonAndAddToCart(salon) {
      if (salon.appointmentButtonOverrideUrl != null) {
        if (salon.appointmentButtonOverrideUrl != "") {
          window.open(salon.appointmentButtonOverrideUrl, "_self");
        }
      } else {
        this.$store.commit("cart/setSelectedSalon", salon.siteId);
        await this.addOrRemove(salon.siteId);
        this.selectSalonDialog = false;
      }
    },
    async addOrRemove(siteId) {
      this.loading = true;
      if (this.employeeId) {
        let employees = this.$store.getters["employees/getEmployeesBySiteId"](this.$store.getters['cart/getSelectedSalon']).filter(x => x.availableForOnlineBooking);
        this.$store.commit('cart/setPreselectedEmployee', employees.find(x => x.base64EmployeeId === this.employeeId))
      }
      if (!this.isDraft()) {
        if (this.treatmentIsInCart(this.treatment)) {
          this.$store.commit('cart/setPreviousSelectedSalon', 0)
          await this.$store.dispatch(
            "cart/deleteShoppingCartItem",
            {item: this.treatment, type: 'T'}
          );
          if (this.$store.getters['cart/getCartCount'] === 0) {
            this.$root.$emit("closeAddedToCartDialog")
          }
        } else {
          let selectedSalon = this.$store.getters["cart/getSelectedSalon"];
          if (selectedSalon) {
            const cart = this.$store.getters['cart/getCart'];
            if (this.salonCanPerformTreatment(selectedSalon)) {
              if (await this.employeeInSalonCanPerformTreatmentCombination()) {
                await this.$store.dispatch(
                  "cart/insertOrUpdateTreatment",
                  this.treatment
                );
                if (!this.isCheckout) {
                  this.$root.$emit("openAddedToCartDialog", ItemType.T);
                }
              } else {
                this.illegalTreatmentCombinationDialog = true;
              }
            } else {
              this.$store.commit('cart/setShowCrossSiteDialog', true)
            }
          } else {
            this.selectSalonDialog = true;
          }
        }
      }
      this.loading = false;
    },
    treatmentIsInCart() {
      return this.$store.getters["cart/treatmentIsInCart"](
        this.isRecEngine ? this.treatment.id : this.treatment.treatmentId
      );
    },
    salonDistance(distance) {
      if (
        distance != null &&
        distance != undefined &&
        distance != NaN &&
        distance != 0 &&
        distance != ""
      ) {
        if (distance < 1) {
          return Math.round(distance * 1000) + " meter unna";
        } else if (distance >= 1 && distance <= 100) {
          return distance.toFixed(1) + " kilometer unna";
        } else if (distance > 100) {
          return Math.round(distance) + " kilometer unna";
        }
      } else {
        return "";
      }
    },
    salonCanPerformTreatment(selectedSalon) {
      return this.treatment.pricePerSite
        .map((x) => x.siteId)
        .includes(selectedSalon);
    },
    async employeeInSalonCanPerformTreatmentCombination() {
      let cart = this.$store.getters["cart/getCart"];
      let employees = this.$store.getters["employees/getEmployeesBySiteId"](this.$store.getters['cart/getSelectedSalon']).filter(x => x.availableForOnlineBooking);
      let success = false;
      /**
       * Always allow adding treatments if no cart already exists
       */
      if (cart && !cart.treatmentIds) {
        success = true;
      } else if (cart && cart.treatmentIds && cart.treatmentIds.length) {
        /**
         * For each employee, check if that emplyoee can perform all treatments in cart
         */
        let treatmentIds = cart.treatmentIds.concat([
          this.isRecEngine ? this.treatment.id : this.treatment.treatmentId,
        ]);
        employees.forEach((emp) => {
          let canPerform = 0;

          treatmentIds.forEach((tId) => {
            if (emp.treatments.filter((x) => x === tId).length) {
              canPerform++;
            }
          });
          /**
           * If the amount of treatments the employee can perfom equals the amount of treatments
           * in cart in total, then that means an employee can fulfill the treatments.
           */
          if (canPerform === treatmentIds.length) {
            success = true;
          }
        });
        /**
         * Also always allow if cart exists but there are no treatments (only products)
         */
      } else {
        success = true;
      }
      return success;
    },
    isDraft() {
      return this.$store.getters["cmsConfigHomepage/isDraftMode"];
    },
    salonImage(salon) {
      var sites = this.$store.getters["cmsConfigHomepage/getSites"];
      var salon = sites.filter((x) => x.siteId == salon.siteId)[0];
      var imageUrl = salon.salonImageUrl;
      if (imageUrl && imageUrl != undefined && imageUrl != null) {
        return imageUrl;
      } else {
        return "https://res.cloudinary.com/odin-systemer-test/image/upload/v1504878858/defaults/d_missing_image.png";
      }
    },
  },
  async mounted() {
    await this.addOrRemove();
    this.salons = this.$store.getters["salon/getSalons"].filter((x) =>
      x.enabled && this.salonCanPerformTreatment(x.siteId)
    );
    await this.$store.dispatch("employees/getSiteEmployees", this.$store.getters["cart/getSelectedSalon"]);
    if (this.salons.length > 1)
      await sortSalonListByUserLocation(this, this.salons);
  },
};
