export const Banner = () => import('../../client/components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../client/components/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CartTabItem = () => import('../../client/components/CartTabItem.vue' /* webpackChunkName: "components/cart-tab-item" */).then(c => wrapFunctional(c.default || c))
export const CrossSalonDialog = () => import('../../client/components/CrossSalonDialog.vue' /* webpackChunkName: "components/cross-salon-dialog" */).then(c => wrapFunctional(c.default || c))
export const Employees = () => import('../../client/components/Employees.vue' /* webpackChunkName: "components/employees" */).then(c => wrapFunctional(c.default || c))
export const EmployeesPerformingTreatment = () => import('../../client/components/EmployeesPerformingTreatment.vue' /* webpackChunkName: "components/employees-performing-treatment" */).then(c => wrapFunctional(c.default || c))
export const FixitDialog = () => import('../../client/components/FixitDialog.vue' /* webpackChunkName: "components/fixit-dialog" */).then(c => wrapFunctional(c.default || c))
export const Giftcard = () => import('../../client/components/Giftcard.vue' /* webpackChunkName: "components/giftcard" */).then(c => wrapFunctional(c.default || c))
export const HomepageFooter = () => import('../../client/components/HomepageFooter.vue' /* webpackChunkName: "components/homepage-footer" */).then(c => wrapFunctional(c.default || c))
export const LandingPageNews = () => import('../../client/components/LandingPageNews.vue' /* webpackChunkName: "components/landing-page-news" */).then(c => wrapFunctional(c.default || c))
export const NewsSlider = () => import('../../client/components/NewsSlider.vue' /* webpackChunkName: "components/news-slider" */).then(c => wrapFunctional(c.default || c))
export const RecEngineModule = () => import('../../client/components/RecEngineModule.vue' /* webpackChunkName: "components/rec-engine-module" */).then(c => wrapFunctional(c.default || c))
export const TreatmentListItem = () => import('../../client/components/TreatmentListItem.vue' /* webpackChunkName: "components/treatment-list-item" */).then(c => wrapFunctional(c.default || c))
export const ChooseSalonDialog = () => import('../../client/components/chooseSalonDialog.vue' /* webpackChunkName: "components/choose-salon-dialog" */).then(c => wrapFunctional(c.default || c))
export const RelatedTreatments = () => import('../../client/components/relatedTreatments.vue' /* webpackChunkName: "components/related-treatments" */).then(c => wrapFunctional(c.default || c))
export const SlideCards = () => import('../../client/components/slideCards.vue' /* webpackChunkName: "components/slide-cards" */).then(c => wrapFunctional(c.default || c))
export const TreatmentCard = () => import('../../client/components/treatmentCard.vue' /* webpackChunkName: "components/treatment-card" */).then(c => wrapFunctional(c.default || c))
export const TreatmentList = () => import('../../client/components/treatmentList.vue' /* webpackChunkName: "components/treatment-list" */).then(c => wrapFunctional(c.default || c))
export const TreatmentsFilter = () => import('../../client/components/treatmentsFilter.vue' /* webpackChunkName: "components/treatments-filter" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
