import { render, staticRenderFns } from "./HomepageFooter.vue?vue&type=template&id=94da731e&scoped=true"
import script from "./HomepageFooter.vue?vue&type=script&lang=js"
export * from "./HomepageFooter.vue?vue&type=script&lang=js"
import style0 from "./HomepageFooter.vue?vue&type=style&index=0&id=94da731e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94da731e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChooseSalonDialog: require('/salonhomepage/client/components/chooseSalonDialog.vue').default})
