import EmployeeService from '../../services/employeesService';
import ProfileSvc from '@fixit/fixit-no-header/src/services/profile-svc'
import Vue from "vue";

export const state = () => ({
    isLoadingEmployees: false,
    // allEmployees: [],
    favoriteEmployees: [],
    employeesMap: {}
})
const getters = {
    getEmployeesBySiteId: state => id => state.employeesMap[id] || [],
    getIsLoadingEmployees: state => state.isLoadingEmployees,
    getFavoriteEmployees: state => state.favoriteEmployees
}

const mutations = {
    setLoadingEmployees(state, value) {
        state.isLoadingEmployees = value
    },
    /**
     * Stores employees by key (siteId). Watcher in HomepageLayout.vue listens to change of selected salon,
     * and adds employees to the list under the designated siteId if it doesn't already exist.
     */
    setEmployeesMap(state, value) {
      if (!state.employeesMap[value.siteId]) {
        Vue.set(state.employeesMap, value.siteId, value.employees)
      }
    },
    setFavoriteEmployees(state, value) {
        state.favoriteEmployees = value
    }
}
const actions = {
    async getSiteEmployees({state, dispatch, commit}, siteId) {
      if (siteId && siteId !== 0 && !state.employeesMap[siteId]) {
        await dispatch('fetchEmployeesBySiteIds', [siteId]).then((employees) => {
          if (employees.length) {
            commit('setEmployeesMap', {siteId: siteId, employees: employees})
          }
        })
      }
    },

    async fetchEmployeesBySiteIds({ commit, getters }, siteIds) {
        commit('setLoadingEmployees', true)
        const employeeService = new EmployeeService({ axios: this.$axios, env: this.$config });
        let result = await employeeService.GetEmployeesBySiteIds(siteIds, false)
        commit('setLoadingEmployees', false)
        return result;
    },
    async fetchFavoriteEmployees({ commit, rootGetters }) {
        const service = await ProfileSvc(this.$axios)
        var siteIds = rootGetters['salon/getSiteIds'];
        var result = await service.GetFavoriteEmployees(this.$config.VUE_APP_FIXIT_NO_BOOKING_URL, siteIds)
        commit('setFavoriteEmployees', result)
    },
    async updateFavoriteEmployees({ commit }, employee) {
        const service = await ProfileSvc(this.$axios)
        return await service.UpdateFavoriteEmployee(this.$config.VUE_APP_FIXIT_NO_BOOKING_URL, employee.employeeId)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
